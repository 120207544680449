import { PLATFORMS } from '../common/src/constants'
import _ from 'lodash'

export const QUERY_PARAMS = {
  MS_TEAMS: 'msteams',
  OUTLOOK: 'outlook',
  PLATFORM: 'platform',
  EMBEDDED: 'embedded',
  REMEMBER: 'remember',
  NO_REDIRECT: 'noredirect',
  FAST_PROJECT: 'fastproject',
  ANDROID: 'android',
  IOS: 'ios',
  ONE_ON_ONE_CREATOR: 'one_on_one_creator',
  ONE_ON_ONE_FIXED: 'one_on_one_fixed',
  SESSION_TOKEN: 'sessiontoken',
  HIDE_HEADER: 'hide_header',
  ONBOARDING: 'onboarding',
  LOGOUT: 'logout',
  IN_MEETING: 'in_meeting',
  NAVIGATED: 'navigated',
  PAYWALL_NEXT: 'paywall_next',
  HIDE_CHROME: 'hide_chrome',
  TAG_FILTER: 'tag_filter',
  FILTER_PRESET: 'filter_preset',
  FROM_NOTIFICATION: 'from_notification',
  COMMERCIAL: 'commercial',
  // Search filter params
  SEARCH_QUERY: 'q',
  ITEM_STATE: 'item_state',
  OWNERS: 'owners',
  TAGS: 'tags',
  PROJECT_IDS: 'project_ids',
  EXCLUDE_PROJECT_IDS: 'exclude_project_ids',
  QUADRANT: 'quadrant',
  SORT: 'sort_mode',
  SORT_DIRECTION: 'sort_direction',
  PROJECT_TAGS: 'project_tags',
  PROJECT_TAGS_MODE: 'project_tags_mode',
  PROJECT_SORT: 'project_sort',
}

export const decodeEmailComponent = _.flow([decodeURIComponent, _.toLower])

export const isWindows = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  const platform = sp.get(QUERY_PARAMS.PLATFORM)
  return _.isString(platform) && _.toLower(platform) === PLATFORMS.WINDOWS
}

export const isEmbeddedOnTeams = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.MS_TEAMS)
}

export const isEmbeddedOnAndroid = () => {
  return !!navigator.userAgent.includes('PMAndroidExpo')
}

export const isEmbeddedOnIos = () => {
  return !!navigator.userAgent.includes('PMIosExpo')
}

export const isEmbeddedOnMobile = () => {
  return isEmbeddedOnAndroid() || isEmbeddedOnIos()
}

export const isEmbeddedOnOutlook = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.OUTLOOK)
}

export const isEmbedded = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.EMBEDDED)
}

export const getRemember = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return _.toSafeInteger(sp.get(QUERY_PARAMS.REMEMBER))
}

export const hasNoRedirect = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.has(QUERY_PARAMS.NO_REDIRECT)
}

export const getSearchParamsWithNoRedirect = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  sp.set(QUERY_PARAMS.NO_REDIRECT, 1)
  return '?' + sp.toString()
}

export const getOneOnOneCreatorQueryParam = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  const creator = sp.get(QUERY_PARAMS.ONE_ON_ONE_CREATOR)
  return creator && decodeEmailComponent(creator)
}

export const isOneOnOneFixedQueryParam = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.has(QUERY_PARAMS.ONE_ON_ONE_FIXED)
}

export const shouldHideHeader = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.HIDE_HEADER) === '1'
}

export const getFastProject = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  let fp = sp.get(QUERY_PARAMS.FAST_PROJECT)
  // readonly link redirection is not working fine on iframes
  // we need to check hash as well
  if (!fp) {
    const hash = window.location.hash
    const hashQuery = hash.substr(1)
    const hashSp = new URLSearchParams(hashQuery)
    fp = hashSp.get(QUERY_PARAMS.FAST_PROJECT)
  }
  return fp || void 0
}

export const showOnboarding = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.ONBOARDING)
}

export const getNextQueryParamFromCurrentLocation = (wasSignedOut = false) => {
  const queryString = window.location.search
  const sp = new URLSearchParams(queryString)
  if (wasSignedOut && !sp.has(QUERY_PARAMS.LOGOUT)) {
    sp.set(QUERY_PARAMS.LOGOUT, '1')
  } else if (!wasSignedOut && sp.has(QUERY_PARAMS.LOGOUT)) {
    sp.delete(QUERY_PARAMS.LOGOUT)
  }
  const uriComponent = window.location.pathname + `?${sp.toString()}`
  return encodeURIComponent(uriComponent)
}

export const isInMeeting = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.IN_MEETING)
}

export const wasNavigated = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.NAVIGATED)
}

export const getPaywallNext = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.PAYWALL_NEXT)
}

export const getSearchParamsWithNavigated = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  sp.set(QUERY_PARAMS.NAVIGATED, 1)
  return '?' + sp.toString()
}

export const hideChrome = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.HIDE_CHROME)
}

export const fromNotification = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.FROM_NOTIFICATION)
}

export const getSearchParamsByTag = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.TAG_FILTER)
}

export const getCommercialNotificationId = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.COMMERCIAL)
}

export const isApptest = () => window.location.href.includes('apptest')

// Search filter params getters with error handling
export const getSearchQuery = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    return sp.get(QUERY_PARAMS.SEARCH_QUERY)
  } catch (error) {
    console.error('Error getting search query from URL:', error)
    return null
  }
}

export const getItemState = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const stateParam = sp.get(QUERY_PARAMS.ITEM_STATE)
    
    if (!stateParam) return null
    
    // Convert text state values to numeric constants
    if (stateParam === 'unfinished') {
      return 0 // itemHelper.STATE.UNFINISHED
    } else if (stateParam === 'finished') {
      return 1 // itemHelper.STATE.DONE
    } else if (stateParam === 'all') {
      return 2 // Used for ALL_ITEMS
    }
    
    return null
  } catch (error) {
    console.error('Error getting item state from URL:', error)
    return null
  }
}

export const getOwners = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const owners = sp.get(QUERY_PARAMS.OWNERS)
    return owners ? owners.split(',') : null
  } catch (error) {
    console.error('Error getting owners from URL:', error)
    return null
  }
}

export const getTags = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const tags = sp.get(QUERY_PARAMS.TAGS)
    return tags ? tags.split(',') : null
  } catch (error) {
    console.error('Error getting tags from URL:', error)
    return null
  }
}

export const getProjectIds = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const projectIds = sp.get(QUERY_PARAMS.PROJECT_IDS)
    return projectIds ? projectIds.split(',') : null
  } catch (error) {
    console.error('Error getting project IDs from URL:', error)
    return null
  }
}

export const getExcludeProjectIds = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const excludeProjectIds = sp.get(QUERY_PARAMS.EXCLUDE_PROJECT_IDS)
    return excludeProjectIds ? excludeProjectIds.split(',') : null
  } catch (error) {
    console.error('Error getting excluded project IDs from URL:', error)
    return null
  }
}

export const getQuadrant = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const quadrant = sp.get(QUERY_PARAMS.QUADRANT)
    return quadrant ? parseInt(quadrant, 10) : null
  } catch (error) {
    console.error('Error getting quadrant from URL:', error)
    return null
  }
}

export const getSortMode = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    return sp.get(QUERY_PARAMS.SORT) || null
  } catch (error) {
    console.error('Error getting sort mode from URL:', error)
    return null
  }
}

export const getSortDirection = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const direction = sp.get(QUERY_PARAMS.SORT_DIRECTION)
    // Convert string "true"/"false" to actual boolean
    return direction === null ? null : direction === 'asc'
  } catch (error) {
    console.error('Error getting sort direction from URL:', error)
    return null
  }
}

export const getProjectTags = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    const tags = sp.get(QUERY_PARAMS.PROJECT_TAGS)
    return tags ? tags.split(',') : null
  } catch (error) {
    console.error('Error getting project tags from URL:', error)
    return null
  }
}

export const getProjectTagsMode = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    return sp.get(QUERY_PARAMS.PROJECT_TAGS_MODE) || null
  } catch (error) {
    console.error('Error getting project tags mode from URL:', error)
    return null
  }
}

export const getProjectSortType = (queryString = window.location.search) => {
  try {
    const sp = new URLSearchParams(queryString)
    return sp.get(QUERY_PARAMS.PROJECT_SORT) || null
  } catch (error) {
    console.error('Error getting project sort type from URL:', error)
    return null
  }
}
